export default [
  {
    images: {
      left: 'https://lmicdn.blob.core.windows.net/common/pci2_vertical_banner.jpg',
      right:
        'https://lmicdn.blob.core.windows.net/common/pci2_vertical_banner.jpg',
    },
    href: {
      left: 'https://lmigroup.io/eservices/policy-comparison/',
      right: 'https://lmigroup.io/eservices/policy-comparison/',
    },
  },
  {
    images: {
      left: 'https://lmicdn.blob.core.windows.net/common/LMILegal-banner.jpg',
      right: 'https://lmicdn.blob.core.windows.net/common/LMILegal-banner.jpg',
    },
    href: {
      left: 'https://lmilegal.com/',
      right: 'https://lmilegal.com/',
    },
  },
  {
    images: {
      left: 'https://lmicdn.blob.core.windows.net/common/LMICollege-ResponsibleManager-banner.jpg',
      right:
        'https://lmicdn.blob.core.windows.net/common/LMICollege-ResponsibleManager-banner.jpg',
    },
    href: {
      left: 'https://lmicollege.edu.au/responsible-manager/',
      right: 'https://lmicollege.edu.au/responsible-manager/',
    },
  },
  {
    images: {
      left: 'https://lmicdn.blob.core.windows.net/common/webinar_banner_feb_march.jpg',
      right:
        'https://lmicdn.blob.core.windows.net/common/webinar_banner_feb_march.jpg',
    },
    href: {
      left: 'https://lmigroup.io/webinars',
      right: 'https://lmigroup.io/webinars',
    },
  },
  {
    images: {
      left: 'https://lmicdn.blob.core.windows.net/common/RC_CustomReporting_Vertical.png',
      right:
        'https://lmicdn.blob.core.windows.net/common/RC_CustomReporting_Vertical.png',
    },
    href: {
      left: 'https://lmigroup.io/riskcoach-tailored-reporting-now-available/',
      right: 'https://lmigroup.io/riskcoach-tailored-reporting-now-available/',
    },
  },
  {
    images: {
      left: 'https://lmicdn.blob.core.windows.net/common/lmi-banner-claims.png',
      right:
        'https://lmicdn.blob.core.windows.net/common/lmi-banner-claims.png',
    },
    href: {
      left: 'https://www.lmigroup.io/services/claims/?catId=148&hideNavigation=true',
      right:
        'https://www.lmigroup.io/services/claims/?catId=148&hideNavigation=true',
    },
  },
  {
    images: {
      left: 'https://lmicdn.blob.core.windows.net/common/lmi-college-eservices-banner-01.jpg',
      right:
        'https://lmicdn.blob.core.windows.net/common/lmi-college-eservices-banner-02.jpg',
    },
    href: {
      left: 'https://lmicollege.edu.au',
      right: 'https://lmicollege.edu.au',
    },
  },
];
